import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

// Redux
import { setDataContact } from "./../../../../@core/redux/actions/app/contact";

//  CSS
import modalStyles from "./../assets/css/Modal.module.css";
import styles from "./../assets/css/Style.module.css";
import buttonStyles from "./../assets/css/Button.module.css";

// Component
import CloseButton from "./CloseButton";

// Icon
import ChatIcon from '@material-ui/icons/Chat';

export default function Contact() {
    const dispatch = useDispatch();
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const homeData = useSelector(state => state.reduxHome.result);
    const [jsonContact, setJsonContact] = useState(null);

    const useStyles = makeStyles((theme) => ({
        // btnPosition: {
        //     position: "absolute",
        //     left: "50%",
        //     bottom: "0px",
        //     transform: "translateX(-50%)"
        // },
        borderContent: {
            color: "#dacfcf",
            marginTop: "20px"
        },
        // boxPadding: {
        //     padding: "20px 40px"
        // },
        contentBoxAlign: {
            marginTop: "20px",
            textAlign: "center"
        },
        // imgAlertSize: {
        //     width: "80px",
        //     height: "auto"
        // },
        // contentBoxAlignText: {
        //     fontSize: "18px",
        //     color: "white"
        // },
        // textYellow: {
        //     color: "rgb(255, 193, 7)"
        // },
        boxContentTitle: {
            fontSize: "28px",
            textShadow: "0px 0px 4px rgb(113 246 255 / 73%)",
            textAlign: "center",
            color: "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "20px",
            }
        },
        // textContent: {
        //     color: "#FEFB95",
        //     fontSize: "16px"
        // },
        // textList: {
        //     marginBlockStart: "4px",
        //     marginBlockEnd: "4px"
        // },
        // cardList: {
        //     background: "linear-gradient(180deg, rgba(38, 25, 118, 0.75) 0%, rgba(47, 0, 107, 0.75) 55.21%, rgba(47, 0, 107, 0.75) 100%)",
        //     boxShadow: "0px 4px 5px rgba(0, 21, 97, 0.6)",
        //     borderRadius: "6px"
        // },
        // buttonBoxSize: {
        //     height: "70px"
        // },
        iconSizeSub: {
            width: "38px",
            height: "38px",
            [theme.breakpoints.down('xs')]: {
                width: "32px",
                height: "32px"
            }
        }
    }));

    const classes = useStyles();

    const closeBox = () => {
        dispatch(setDataContact(false));
    };

    const loadLiveChat = () => {
        window.LiveChatWidget.call("maximize");
        window.LiveChatWidget.call('set_customer_name', `${homeData !== null ? homeData.username : "-"}`);
        window.LiveChatWidget.call("set_session_variables", {
            username: (homeData !== null ? homeData.username : "-"),
            balance: (homeData !== null ? homeData.balance : 0),
            phone: (homeData !== null ? (homeData.phone === "" ? "-" : homeData.phone) : "-")
        });
    };

    const gotoContact = (data, type) => {
        if (type === "line") {
            window.open(data.channel_link, "_blank");
        }
        else {
            loadLiveChat();
        }
    };

    useEffect(() => {
        if (dataSetting?.agent_contact !== undefined) {
            setJsonContact(dataSetting.agent_contact);
        }
    }, [dataSetting]);

    return (
        <>
            <div className={modalStyles.contentPosition} style={{ zIndex: "1805" }}>
                <div className={modalStyles.boxBody}>
                    <div className={modalStyles.box}>
                        <div className={modalStyles.closeButton} onClick={closeBox}>
                            <CloseButton></CloseButton>
                        </div>
                        <div className={[modalStyles.boxContentWidthBig].join(" ")}>
                            <div className={modalStyles.boxContentPadding}>
                                <div className={[classes.boxContentTitle, styles.center].join(" ")} style={{ marginTop: "0px" }}>
                                    ติดต่อแอดมิน
                                </div>
                                <br />
                                <div className={[classes.borderContent, "center"].join(" ")}>
                                    {
                                        jsonContact !== null && (
                                            jsonContact.map((item, index) => {
                                                return (
                                                    item.contact_channel === 0 ?
                                                        <button className={[buttonStyles.btnLogin, buttonStyles.btnContactSize, buttonStyles.btnLine].join(" ")} onClick={() => gotoContact(item, "line")} key={index}>
                                                            <Grid container alignItems="center" justifyContent="center" spacing={4}>
                                                                <Grid item>
                                                                    <img src="/images/icon/line_white.webp" className={[styles.iconSizeSub, classes.iconSizeSub].join(" ")} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                                </Grid>
                                                                <Grid item>
                                                                    ติดต่อผ่านไลน์
                                                                </Grid>
                                                            </Grid>
                                                        </button>
                                                        :
                                                        item.contact_channel === 3 ?
                                                            <button className={[buttonStyles.btnLogin, buttonStyles.btnContactSize, buttonStyles.btnLine].join(" ")} onClick={() => gotoContact(item, "line")} key={index}>
                                                                <Grid container alignItems="center" justifyContent="center" spacing={4}>
                                                                    <Grid item>
                                                                        <img src="/images/icon/contact_telegram.png" className={[styles.iconSizeSub, classes.iconSizeSub].join(" ")} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        เทเลแกรม
                                                                    </Grid>
                                                                </Grid>
                                                            </button>
                                                            :
                                                            <button className={[buttonStyles.btnLogin, buttonStyles.btnContactSize, styles.boxDetailDeposit, buttonStyles.btnLiveChat].join(" ")} onClick={() => gotoContact(item, "liveChat")} key={index}>
                                                                <Grid container alignItems="center" justifyContent="center" spacing={4}>
                                                                    <Grid item>
                                                                        <ChatIcon className={[styles.iconSizeSub, classes.iconSizeSub].join(" ")}></ChatIcon>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        ติดต่อผ่านไลฟ์แชท
                                                                    </Grid>
                                                                </Grid>
                                                            </button>
                                                )
                                            })
                                        )
                                    }

                                    <br /><br />
                                    <div className={[styles.boxComemntTop, styles.center].join(" ")}>
                                        มีพนักงานพร้อมให้บริการตลอด 24 ชม.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={modalStyles.fixedBackdrop} style={{ zIndex: "1804", opacity: "0.9" }} onClick={closeBox}></div>
        </>
    );
}